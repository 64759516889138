import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 212px;
  height: 100vh;
  width: 99vw;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 64px;
  text-align: center;
  width: 100%;
  max-width: 1328px;

  h1 {
    margin-bottom: 32px;
    @media screen and (max-width: 1000px) {
      font-size: 40px;
      margin-bottom: 26px;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 14px;
    }
  }

  .last {
    padding-bottom: 64px;
    margin-bottom: 0;
  }
`;

export const Data = styled.div`
  width: 90%;
  padding: 64px 0;
  @media screen and (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(17, 17, 17, 0.7);
  padding: 16px 0;
  p {
    font-weight: 700;
  }
`;

export const DataDate = styled.div`
  display: flex;
`;

export const DataTax = styled.div``;
