import styled from 'styled-components';

export const HiddenDiv = styled.div`
  display: none;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 212px;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const StickyButton = styled.div`
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  border-top: 1px solid #fff;
  padding: 16px 0;
  background-color: #fff;
  margin-bottom: -89px;
  align-items: center;

  button {
    width: 82%;
    height: 56px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 569px) {
    display: none;
  }
`;
