import React, { useEffect, useRef, useState, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';

import { InputContainer, Container, Error, Label } from './styles';

import checkIconBlack from '../../assets/check-icon-black.svg';
import errorIcon from '../../assets/error-icon.svg';

const MaskedInput = ({
  name,
  icon: Icon,
  mask,
  label,
  containerType,
  onBlur: onBlurProps,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const {
    fieldName,
    defaultValue,
    error,
    registerField,
    clearError,
  } = useField(name);

  const handleInputFocus = useCallback(() => {
    clearError();
    setIsFocused(true);
  }, [clearError]);

  const handleBlur = event => {
    event.persist();
    if (onBlurProps) onBlurProps(event);
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  };

  useEffect(
    () =>
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      }),
    [fieldName, registerField],
  );

  return (
    <Container containerType={containerType}>
      <Label htmlFor={name} isFilled={isFilled}>
        {label}
      </Label>
      <InputContainer isErrored={!!error} isFocused={isFocused}>
        <InputMask
          autoComplete="nope"
          onFocus={handleInputFocus}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          mask={mask}
          ref={inputRef}
          {...rest}
        />
        {!error && isFilled && !isFocused && containerType !== 'hidden' && (
          <img src={checkIconBlack} alt="Check icon" />
        )}
        {error && <img src={errorIcon} alt="Error icon" />}
        {error && <Error title={error}>{error}</Error>}
      </InputContainer>
    </Container>
  );
};

export default MaskedInput;
