import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${props =>
    !props.containerType &&
    css`
      padding: 8px 0;
    `}
`;

export const Label = styled.label`
  display: inline-block;
  font-family: 'Nunito', sans-serif;
  color: ${props => (!props.isFilled ? '#8e8e8e' : '#000')};
  font-size: 14px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 40px;
  position: relative;

  input {
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    background-color: #fff;
    padding: 10px 15px 5px 0;
    border: none;
    border-bottom: 2px solid #000;

    border-radius: 0;
    width: 100%;
    color: #000;
    &:focus {
      outline: none;
    }
    ${props =>
      props.isErrored &&
      css`
        border-color: #db3939;
      `}
    ${props =>
      props.isFocused &&
      css`
        border-color: #0090ff;
      `}
  }

  input:disabled {
    border-bottom: 2px solid #8e8e8e;
  }

  img {
    position: absolute;
    right: 8px;
    top: 20px;
    ${props =>
      props.isErrored &&
      css`
        top: 15px;
      `}
  }
`;

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Nunito', sans-serif;
  padding: 4px 0;
  font-size: 10px;
`;
