import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useField } from '@unform/core';
import { Container, Star, Wrap, Error, Stars } from './styles';

const Satisfaction = ({ name }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  const [selectedStar, setSelectedStar] = useState(0);
  const [hoveredStar, setHoveredStar] = useState(0);

  useEffect(
    () =>
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      }),
    [fieldName, registerField],
  );

  const hoverStarOn = starNumber => {
    setHoveredStar(starNumber);
  };

  const hoverStarOff = () => {
    setHoveredStar(0);
  };

  const selectStar = useCallback(
    starNumber => {
      clearError();
      setSelectedStar(starNumber);
    },
    [clearError],
  );

  return (
    <Container>
      <Stars>
        <input
          type="hidden"
          name={fieldName}
          value={selectedStar}
          ref={inputRef}
        />
        <Wrap selectedStar={selectedStar} hoveredStar={hoveredStar}>
          <Star
            id={1}
            selectedStar={selectedStar}
            hoveredStar={hoveredStar}
            className="star-one"
            onMouseEnter={() => hoverStarOn(1)}
            onMouseLeave={() => hoverStarOff()}
            onClick={() => selectStar(1)}
          />
          <span className="span-one">Péssimo</span>
        </Wrap>
        <Wrap selectedStar={selectedStar} hoveredStar={hoveredStar}>
          <Star
            id={2}
            selectedStar={selectedStar}
            hoveredStar={hoveredStar}
            className="star-two"
            onMouseEnter={() => hoverStarOn(2)}
            onMouseLeave={() => hoverStarOff()}
            onClick={() => selectStar(2)}
          />
          <span className="span-two">Ruim</span>
        </Wrap>
        <Wrap selectedStar={selectedStar} hoveredStar={hoveredStar}>
          <Star
            id={3}
            selectedStar={selectedStar}
            hoveredStar={hoveredStar}
            className="star-three"
            onMouseEnter={() => hoverStarOn(3)}
            onMouseLeave={() => hoverStarOff()}
            onClick={() => selectStar(3)}
          />
          <span className="span-three">Neutro</span>
        </Wrap>
        <Wrap selectedStar={selectedStar} hoveredStar={hoveredStar}>
          <Star
            id={4}
            selectedStar={selectedStar}
            hoveredStar={hoveredStar}
            className="star-four"
            onMouseEnter={() => hoverStarOn(4)}
            onMouseLeave={() => hoverStarOff()}
            onClick={() => selectStar(4)}
          />
          <span className="span-four">Bom</span>
        </Wrap>
        <Wrap selectedStar={selectedStar} hoveredStar={hoveredStar}>
          <Star
            id={5}
            selectedStar={selectedStar}
            hoveredStar={hoveredStar}
            className="star-five"
            onMouseEnter={() => hoverStarOn(5)}
            onMouseLeave={() => hoverStarOff()}
            onClick={() => selectStar(5)}
          />
          <span className="span-five">Excelente</span>
        </Wrap>
      </Stars>
      {error && <Error title={error}>{error}</Error>}
    </Container>
  );
};

export default Satisfaction;
