import React from 'react';

import OmbudsmanForm from '../../components/OmbudsmanForm';

import { Container } from './styles';

const FormularioOuvidoria = () => {
  return (
    <Container>
      <OmbudsmanForm />
    </Container>
  );
};

export default FormularioOuvidoria;
