/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react';

import { cpf } from 'cpf-cnpj-validator';

import { Form } from '@unform/web';
import * as Yup from 'yup';

// import checkIconWhite from '../../assets/check-icon-white.svg';

import { Container, Content } from './styles';

import arrowRight from '../../assets/arrow-right.svg';
import thumbsCheck from '../../assets/thumbs-check.svg';

import Input from '../Input';
import MaskedInput from '../MaskedInput';
import Button from '../Button';
import Satisfaction from '../Satisfaction';
import TextArea from '../TextArea';

const OmbudsmanForm = () => {
  const formRef = useRef(null);
  const [step, setStep] = useState('first');

  const schema = Yup.object().shape({
    protocol: Yup.string().required('Protocolo é obrigatório.'),
    name: Yup.string()
      .min(2, 'Digite pelo menos 2 letras.')
      .required('Nome obrigatório. Tente novamente.'),
    email: Yup.string()
      .required('E-mail é obrigatório.')
      .email('E-mail inválido. Tente novamente.'),
    cpf: Yup.string()
      .test('Valida CPF', 'CPF inválido. Tente novamente. ', function () {
        return cpf.isValid(formRef.current.getFieldValue('cpf'));
      })
      .required('CPF é obrigatório.'),
    comment: Yup.string().required('Comentário é obrigatório.'),
    quality: Yup.string()
      .matches(/(1|2|3|4|5)/, 'Necessário selecionar alguma nota.')
      .required('Necessário selecionar alguma nota.'),
    solution: Yup.string()
      .matches(/(1|2|3|4|5)/, 'Necessário selecionar alguma nota.')
      .required('Necessário selecionar alguma nota.'),
    ombudsman: Yup.string()
      .matches(/(1|2|3|4|5)/, 'Necessário selecionar alguma nota.')
      .required('Necessário selecionar alguma nota.'),
  });

  const validateOnBlur = async event => {
    const fieldName = event.currentTarget.id;

    if (formRef.current.getFieldValue('name')) {
      const unNumber = formRef.current.getFieldValue('name').replace(/\d/g, '');

      formRef.current.setFieldValue('name', unNumber);
    }

    try {
      formRef.current.setFieldError(fieldName, '');
      await Yup.reach(schema, fieldName).validate(
        formRef.current.getFieldValue(fieldName),
        {
          abortEarly: false,
        },
      );
    } catch (err) {
      const validationError = [];
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationError.path = error.message;
        });
        formRef.current.setFieldError(fieldName, validationError.path);
      }
    }
  };

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        });

        // const dataModified = await formRef.current.getData();

        console.log('Data modified submit: ', data);

        // await postData(process.env.REACT_APP_URL, dataModified)
        //   .then(data => {
        //     // JSON data parsed by `data.json()` call
        //   })
        //   .catch(err => console.log(err));
        setStep('finished');
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      }
    },
    [schema],
  );

  return (
    <Container>
      <Content step={step}>
        {step !== 'finished' ? (
          <>
            <div className="form-wrapper">
              <h1>
                Já foi atendido por nossa Ouvidoria?
                <br />
                Deixe sua avaliação, ela é muito importante pra nós!
              </h1>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="step">
                  <div className="step-count">1</div>
                  <div className="step-fields">
                    <div className="step-data">
                      <h2>Informe os dados do atendimento</h2>
                    </div>
                    <Input
                      id="protocol"
                      label="Protocolo"
                      name="protocol"
                      size="20"
                      type="text"
                      placeholder="Insira o número do seu protocolo"
                      onBlur={validateOnBlur}
                    />
                    <br />

                    <Input
                      id="name"
                      label="Nome"
                      name="name"
                      size="20"
                      type="text"
                      placeholder="Insira seu nome"
                      onBlur={validateOnBlur}
                    />
                    <br />

                    <MaskedInput
                      id="cpf"
                      name="cpf"
                      label="CPF ou CNPJ"
                      size="20"
                      type="text"
                      mask="999.999.999-99"
                      placeholder="000.000.000-00"
                      onBlur={validateOnBlur}
                    />
                    <br />

                    <Input
                      id="email"
                      name="email"
                      label="E-mail"
                      size="20"
                      type="text"
                      placeholder="Ex: email@email.com.br"
                      onBlur={validateOnBlur}
                    />
                    <br />
                  </div>
                </div>

                <div className="step">
                  <div className="step-count">2</div>
                  <div className="step-fields">
                    <div className="step-data">
                      <h2>Sobre a sua avaliação</h2>
                    </div>
                    <p>
                      Pedimos, que você avalie seu nível de satisfação com o
                      atendimento prestado pela ouvidoria a sua demanda.
                    </p>
                    <p>
                      Use uma 1 estrela o nível de satisfação mais baixo e 5
                      estrelas o nível de satisfação mais alto.
                    </p>
                    <div className="satisfaction-container">
                      <div className="question">
                        <div className="question-text">
                          <div className="question-text-letter">
                            <span>A.</span>
                          </div>
                          <div className="question-text-asking">
                            <span>
                              Qual seu nível de satisfação com a solução
                              apresentada pela ouvidoria?
                            </span>
                            <Satisfaction id="solution" name="solution" />
                          </div>
                        </div>
                      </div>

                      <div className="question">
                        <div className="question-text">
                          <div className="question-text-letter">
                            <span>B.</span>
                          </div>
                          <div className="question-text-asking">
                            <span>
                              Qual seu nível de satisfação com a qualidade do
                              atendimento prestado pela ouvidoria?
                            </span>
                            <Satisfaction id="quality" name="quality" />
                          </div>
                        </div>
                      </div>

                      <div className="question">
                        <div className="question-text">
                          <div className="question-text-letter">
                            <span>C.</span>
                          </div>
                          <div className="question-text-asking">
                            <span>
                              O ouvidor se apresentou solícito ao encaminhamento
                              de solução à sua demanda?
                            </span>
                            <Satisfaction id="ombudsman" name="ombudsman" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="step">
                  <div className="step-count">3</div>
                  <div className="step-fields step-fields-last">
                    <div className="step-data">
                      <h2>Adicione um comentário</h2>
                      <span>Opcional</span>
                    </div>

                    <TextArea
                      id="comment"
                      name="comment"
                      multiline
                      label="Comentário"
                      size="200"
                      type="text"
                      placeholder="..."
                      onBlur={validateOnBlur}
                    />
                    <br />

                    <Button
                      id="feedback"
                      type="submit"
                      name="submit"
                      className="feedback"
                    >
                      Enviar avaliação
                      <img src={arrowRight} alt="Arrow right" />
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </>
        ) : (
          <div className="thanks">
            <img
              className="congratz"
              src={thumbsCheck}
              alt="Check e thumbsup"
            />
            <span className="thanks-title">
              Muito obrigado por nos avaliar!
            </span>
            <span className="thanks-subtitle">
              Caso haja necessidade nós entraremos em contato.
            </span>
          </div>
        )}
      </Content>
    </Container>
  );
};

export default OmbudsmanForm;
