import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #fff;
    color: #000;
    -webkit-font-smoothing: antialiased;
    display: flex;
    width: 100%;
  }

  #root {
    width: 100%;
  }

  body, input, button {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }
`;
