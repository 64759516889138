import React, { useEffect, useState } from 'react';

import { Container, Content, Data, DataRow, DataDate, DataTax } from './styles';
import dolarApi from '../../services/dolarApi';

const DadosAbertos = () => {
  const [quotations, setQuotations] = useState([]);

  useEffect(() => {
    dolarApi.get().then(response => {
      setQuotations(response.data.exchangeRates);
    });
  }, []);

  const convertDate = date => {
    const splitedDate = date.split('-');
    return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`;
  };

  return (
    <Container>
      <Content>
        <h1>
          Taxas de conversão do Dólar
          <br />
          dos Estados Unidos para Reais
        </h1>
        <p>
          Estas taxas são utilizadas para compras internacionais com o cartão de
          crédito
        </p>
        <p className="last">
          Última atualizacão em:
          {' '.concat(new Date().toLocaleDateString('pt-br'))}
        </p>

        <Data>
          <DataRow>
            <DataDate>
              <p>Data</p>
            </DataDate>
            <DataTax>
              <p>Taxa(USD/BRL)</p>
            </DataTax>
          </DataRow>

          {quotations &&
            quotations.map(quotation => (
              <DataRow key={quotation.referenceDate}>
                <DataDate>{convertDate(quotation.referenceDate)}</DataDate>
                <DataTax>{(quotation.rate).toPrecision(5)}</DataTax>
              </DataRow>
            ))}
        </Data>
      </Content>
    </Container>
  );
};

export default DadosAbertos;
