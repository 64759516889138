import styled, { css } from 'styled-components';

import star from '../../assets/star.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: -6px;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 46px;
  align-items: center;

  span {
    font-size: 14px;
    padding-top: 8px;
    visibility: hidden;
    color: #000;
    opacity: 0.5;
    font-weight: 600;
  }

  ${props =>
    props.hoveredStar === 5
      ? css`
          .span-five {
            visibility: visible;
          }
        `
      : props.hoveredStar === 4
      ? css`
          .span-four {
            visibility: visible;
          }
        `
      : props.hoveredStar === 3
      ? css`
          .span-three {
            visibility: visible;
          }
        `
      : props.hoveredStar === 2
      ? css`
          .span-two {
            visibility: visible;
          }
        `
      : props.hoveredStar === 1 &&
        css`
          .span-one {
            visibility: visible;
          }
        `}

  ${props =>
    props.selectedStar === 5
      ? css`
          .span-five {
            visibility: visible;
            opacity: 1;
          }
        `
      : props.selectedStar === 4
      ? css`
          .span-four {
            visibility: visible;
            opacity: 1;
          }
        `
      : props.selectedStar === 3
      ? css`
          .span-three {
            visibility: visible;
            opacity: 1;
          }
        `
      : props.selectedStar === 2
      ? css`
          .span-two {
            visibility: visible;
            opacity: 1;
          }
        `
      : props.selectedStar === 1 &&
        css`
          .span-one {
            visibility: visible;
            opacity: 1;
          }
        `}

        ${props =>
    props.hoveredStar === 5
      ? css`
          .star-one,
          .star-two,
          .star-three,
          .star-four,
          .star-five {
            background-color: #000;
            opacity: 0.5;
          }
        `
      : props.hoveredStar === 4
      ? css`
          .star-one,
          .star-two,
          .star-three,
          .star-four {
            background-color: #000;
            opacity: 0.5;
          }
        `
      : props.hoveredStar === 3
      ? css`
          .star-one,
          .star-two,
          .star-three {
            background-color: #000;
            opacity: 0.5;
          }
        `
      : props.hoveredStar === 2
      ? css`
          .star-one,
          .star-two {
            background-color: #000;
            opacity: 0.5;
          }
        `
      : props.hoveredStar === 1 &&
        css`
          .star-one {
            background-color: #000;
            opacity: 0.5;
          }
        `}

  ${props =>
    props.selectedStar === 5
      ? css`
          .star-one,
          .star-two,
          .star-three,
          .star-four,
          .star-five {
            background-color: #0090ff;
          }
        `
      : props.selectedStar === 4
      ? css`
          .star-one,
          .star-two,
          .star-three,
          .star-four {
            background-color: #0090ff;
          }
        `
      : props.selectedStar === 3
      ? css`
          .star-one,
          .star-two,
          .star-three {
            background-color: #0090ff;
          }
        `
      : props.selectedStar === 2
      ? css`
          .star-one,
          .star-two {
            background-color: #0090ff;
          }
        `
      : props.selectedStar === 1 &&
        css`
          .star-one {
            background-color: #0090ff;
          }
        `}
`;

export const Star = styled.div`
  height: 30px;
  width: 30px;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url(${star});
  background-color: #e6e6e6;
`;

export const Stars = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Nunito', sans-serif;
  padding: 4px 0 !important;
  font-size: 10px !important;
`;
