import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  z-index: 9999;

  h1 {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 90px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0;

    h1 {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 70px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  #feedback {
    width: calc(100% - 40px);
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;

    h1 {
      text-align: left;
    }

    form {
      max-width: 550px;

      .hidden {
        display: none;
      }

      .step {
        display: flex;
        margin-bottom: 32px;
        justify-content: space-between;
        align-items: flex-start;

        .step-count {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 32px;
          height: 32px;
          background-color: #000;
          color: #fff;
          border-radius: 8px;
          margin-top: 6px;
          margin-right: 30px;
        }

        p {
          margin-bottom: 16px;
        }

        .step-fields {
          border-bottom: 2px solid rgb(230, 230, 230) !important;
          padding-bottom: 48px;
        }

        .step-fields-last {
          border-bottom: none !important;
          width: 100%;
        }

        .step-data {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
          width: 100%;

          h2 {
            font-size: 32px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 16px;
          }
          span {
            font-size: 18px;
            color: #c2c2c2;
          }
        }

        .satisfaction-container {
          margin-top: 56px;

          .question {
            display: flex;
            flex-direction: column;

            .question-text {
              display: flex;

              span {
                font-weight: 600;
              }

              span + span {
                padding-left: 6px;
              }

              &-asking {
                margin-left: 6px;
              }
            }
          }
        }
      }

      .name-div {
        display: flex;
        flex-direction: row;

        div {
          align-self: flex-start;
        }

        div + div {
          margin-left: 16px;
        }
      }

      button {
        width: 100%;
        color: #fff;
        background-color: #000;
        border-radius: 8px;
        height: 55px;
        margin: 40px 0 60px;
      }

      .quero-ser-parceiro {
        margin-bottom: 0;
        margin-top: 30px;
      }
    }
  }

  .thanks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 490px;

    @media screen and (max-width: 768px) {
      .congratz {
        width: 90px;
        height: 109px;
      }
    }

    .thanks-title {
      margin-top: 56px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
    }

    .thanks-subtitle {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #626262;
    }
  }

  @media screen and (max-width: 768px) {
    .form-wrapper {
      width: 82%;

      .vertical-line {
        display: none;
      }

      form {
        max-width: 550px !important;

        .step {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 0;
          .step-count {
            margin-left: 0;
            margin-bottom: 16px;
          }

          .step-data {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            h2 {
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 8px;
            }
            span {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 70px;
    }
  }
`;

export const ChoiceButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-size: 18px;
    margin-bottom: 10px;

    span {
      color: #8e8e8e;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    button {
      border-radius: 10px !important;
      background-color: transparent !important;
      border: 1px solid #000 !important;
      margin: 0 !important;
      color: #000 !important;
      ${props =>
        props.personType === 'Pessoa Física' || props.companyHasSite === true
          ? `background-color: #000 !important;
          color: #fff !important;
        `
          : null};

      @media screen and (max-width: 568px) {
        padding: 14px 11px;
      }
    }

    button + button {
      margin-left: 16px !important;
      background-color: transparent !important;
      color: #000 !important;
      ${props =>
        props.personType === 'Pessoa Jurídica' || props.companyHasSite === false
          ? `background-color: #000 !important;
          color: #fff !important;
        `
          : null};
    }
  }
`;
