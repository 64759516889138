import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import FormularioOuvidoria from '../pages/FormularioOuvidoria';
import DadosAbertos from '../pages/DadosAbertos';
import Download from '../pages/Download';

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/iframe/feedback-ouvidoria"
      component={FormularioOuvidoria}
    />
    <Route exact path="/iframe/dados-abertos" component={DadosAbertos} />
    <Route exact path="/iframe/download" component={Download} />
  </Switch>
);

export default Routes;
