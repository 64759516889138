import React from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useEffect } from 'react';

export default function Download() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isMobile) {
        if (isIOS) {
          window.location.href = 'https://apps.apple.com/br/app/banco-bari/id1461506533';
        } else {
          window.location.href = 'https://play.google.com/store/apps/details?id=br.com.bancobari';
        }
      } else {
        window.location.href = '/';
      }
    }
  });

  return <h1>...</h1>;
}
