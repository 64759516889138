import styled from 'styled-components';

export const Container = styled.button`
  background: #0090ff;
  height: 44px;
  border-radius: 4px;
  border: 0;
  padding: 12px 36px;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
